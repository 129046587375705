// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apotheken-nutzungsbedingungen-js": () => import("./../../../src/pages/apotheken/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-apotheken-nutzungsbedingungen-js" */),
  "component---src-pages-app-apotheke-js": () => import("./../../../src/pages/app/apotheke.js" /* webpackChunkName: "component---src-pages-app-apotheke-js" */),
  "component---src-pages-app-checkout-js": () => import("./../../../src/pages/app/checkout.js" /* webpackChunkName: "component---src-pages-app-checkout-js" */),
  "component---src-pages-app-customerportal-js": () => import("./../../../src/pages/app/customerportal.js" /* webpackChunkName: "component---src-pages-app-customerportal-js" */),
  "component---src-pages-app-hilfe-js": () => import("./../../../src/pages/app/hilfe.js" /* webpackChunkName: "component---src-pages-app-hilfe-js" */),
  "component---src-pages-app-nutzungsbedingungen-js": () => import("./../../../src/pages/app/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-app-nutzungsbedingungen-js" */),
  "component---src-pages-ch-de-404-js": () => import("./../../../src/pages/ch-de/404.js" /* webpackChunkName: "component---src-pages-ch-de-404-js" */),
  "component---src-pages-ch-de-about-js": () => import("./../../../src/pages/ch-de/about.js" /* webpackChunkName: "component---src-pages-ch-de-about-js" */),
  "component---src-pages-ch-de-aerzte-js": () => import("./../../../src/pages/ch-de/aerzte.js" /* webpackChunkName: "component---src-pages-ch-de-aerzte-js" */),
  "component---src-pages-ch-de-apotheken-js": () => import("./../../../src/pages/ch-de/apotheken.js" /* webpackChunkName: "component---src-pages-ch-de-apotheken-js" */),
  "component---src-pages-ch-de-checkout-js": () => import("./../../../src/pages/ch-de/checkout.js" /* webpackChunkName: "component---src-pages-ch-de-checkout-js" */),
  "component---src-pages-ch-de-contactme-js": () => import("./../../../src/pages/ch-de/contactme.js" /* webpackChunkName: "component---src-pages-ch-de-contactme-js" */),
  "component---src-pages-ch-de-impressum-js": () => import("./../../../src/pages/ch-de/impressum.js" /* webpackChunkName: "component---src-pages-ch-de-impressum-js" */),
  "component---src-pages-ch-de-index-js": () => import("./../../../src/pages/ch-de/index.js" /* webpackChunkName: "component---src-pages-ch-de-index-js" */),
  "component---src-pages-ch-de-onboarding-js": () => import("./../../../src/pages/ch-de/onboarding.js" /* webpackChunkName: "component---src-pages-ch-de-onboarding-js" */),
  "component---src-pages-ch-de-preise-js": () => import("./../../../src/pages/ch-de/preise.js" /* webpackChunkName: "component---src-pages-ch-de-preise-js" */),
  "component---src-pages-ch-de-quickformpage-js": () => import("./../../../src/pages/ch-de/quickformpage.js" /* webpackChunkName: "component---src-pages-ch-de-quickformpage-js" */),
  "component---src-pages-ch-de-vorteile-js": () => import("./../../../src/pages/ch-de/vorteile.js" /* webpackChunkName: "component---src-pages-ch-de-vorteile-js" */),
  "component---src-pages-ch-fr-404-js": () => import("./../../../src/pages/ch-fr/404.js" /* webpackChunkName: "component---src-pages-ch-fr-404-js" */),
  "component---src-pages-ch-fr-about-js": () => import("./../../../src/pages/ch-fr/about.js" /* webpackChunkName: "component---src-pages-ch-fr-about-js" */),
  "component---src-pages-ch-fr-aerzte-js": () => import("./../../../src/pages/ch-fr/aerzte.js" /* webpackChunkName: "component---src-pages-ch-fr-aerzte-js" */),
  "component---src-pages-ch-fr-apotheken-js": () => import("./../../../src/pages/ch-fr/apotheken.js" /* webpackChunkName: "component---src-pages-ch-fr-apotheken-js" */),
  "component---src-pages-ch-fr-checkout-js": () => import("./../../../src/pages/ch-fr/checkout.js" /* webpackChunkName: "component---src-pages-ch-fr-checkout-js" */),
  "component---src-pages-ch-fr-contactme-js": () => import("./../../../src/pages/ch-fr/contactme.js" /* webpackChunkName: "component---src-pages-ch-fr-contactme-js" */),
  "component---src-pages-ch-fr-datenschutzvereinbarung-js": () => import("./../../../src/pages/ch-fr/datenschutzvereinbarung.js" /* webpackChunkName: "component---src-pages-ch-fr-datenschutzvereinbarung-js" */),
  "component---src-pages-ch-fr-impressum-js": () => import("./../../../src/pages/ch-fr/impressum.js" /* webpackChunkName: "component---src-pages-ch-fr-impressum-js" */),
  "component---src-pages-ch-fr-index-js": () => import("./../../../src/pages/ch-fr/index.js" /* webpackChunkName: "component---src-pages-ch-fr-index-js" */),
  "component---src-pages-ch-fr-nutzungsbedingungen-js": () => import("./../../../src/pages/ch-fr/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-ch-fr-nutzungsbedingungen-js" */),
  "component---src-pages-ch-fr-onboarding-js": () => import("./../../../src/pages/ch-fr/onboarding.js" /* webpackChunkName: "component---src-pages-ch-fr-onboarding-js" */),
  "component---src-pages-ch-fr-preise-js": () => import("./../../../src/pages/ch-fr/preise.js" /* webpackChunkName: "component---src-pages-ch-fr-preise-js" */),
  "component---src-pages-ch-fr-quickformpage-js": () => import("./../../../src/pages/ch-fr/quickformpage.js" /* webpackChunkName: "component---src-pages-ch-fr-quickformpage-js" */),
  "component---src-pages-ch-fr-vorteile-js": () => import("./../../../src/pages/ch-fr/vorteile.js" /* webpackChunkName: "component---src-pages-ch-fr-vorteile-js" */),
  "component---src-pages-change-email-js": () => import("./../../../src/pages/change-email.js" /* webpackChunkName: "component---src-pages-change-email-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-datenschutzvereinbarung-js": () => import("./../../../src/pages/datenschutzvereinbarung.js" /* webpackChunkName: "component---src-pages-datenschutzvereinbarung-js" */),
  "component---src-pages-de-de-404-js": () => import("./../../../src/pages/de-de/404.js" /* webpackChunkName: "component---src-pages-de-de-404-js" */),
  "component---src-pages-de-de-about-js": () => import("./../../../src/pages/de-de/about.js" /* webpackChunkName: "component---src-pages-de-de-about-js" */),
  "component---src-pages-de-de-apotheken-js": () => import("./../../../src/pages/de-de/apotheken.js" /* webpackChunkName: "component---src-pages-de-de-apotheken-js" */),
  "component---src-pages-de-de-contactme-js": () => import("./../../../src/pages/de-de/contactme.js" /* webpackChunkName: "component---src-pages-de-de-contactme-js" */),
  "component---src-pages-de-de-index-js": () => import("./../../../src/pages/de-de/index.js" /* webpackChunkName: "component---src-pages-de-de-index-js" */),
  "component---src-pages-de-de-onboarding-js": () => import("./../../../src/pages/de-de/onboarding.js" /* webpackChunkName: "component---src-pages-de-de-onboarding-js" */),
  "component---src-pages-de-de-preise-js": () => import("./../../../src/pages/de-de/preise.js" /* webpackChunkName: "component---src-pages-de-de-preise-js" */),
  "component---src-pages-de-de-vorteile-js": () => import("./../../../src/pages/de-de/vorteile.js" /* webpackChunkName: "component---src-pages-de-de-vorteile-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-emailsettings-js": () => import("./../../../src/pages/emailsettings.js" /* webpackChunkName: "component---src-pages-emailsettings-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nutzungsbedingungen-js": () => import("./../../../src/pages/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-passwortreset-js": () => import("./../../../src/pages/passwortreset.js" /* webpackChunkName: "component---src-pages-passwortreset-js" */),
  "component---src-pages-payment-failed-js": () => import("./../../../src/pages/payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-remindme-js": () => import("./../../../src/pages/remindme.js" /* webpackChunkName: "component---src-pages-remindme-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-vollmacht-js": () => import("./../../../src/pages/vollmacht.js" /* webpackChunkName: "component---src-pages-vollmacht-js" */)
}

